var rangeSlider = require('rangeslider-pure');
window.$ = require('jquery');
var Cocoen = require('cocoen');
var intlTelInput = require('intl-tel-input');
var attachBanner = require('@beyonk/gdpr-cookie-consent-banner/dist/esm/bundle.js');

(function () {
	var GCLID = 'gclid';
	var FBCLID = 'fbclid';

	function countryCheck() {
		const COUNTRY = 'country';
		const queryParams = {};

		new URLSearchParams(window.location.search)
			.forEach((value, key) => queryParams[key] = value);

		if (queryParams[COUNTRY])
			sessionStorage.setItem(COUNTRY, queryParams.country);
	}

	countryCheck();

	window.onload = function () {
		var mobileMenuShown = false;
		var videoShown = false;

		var burgerBtn = document.querySelector('.burger-button');
		var mobileMenu = document.querySelector('.nav-items.mobile');

		var playBtn = document.querySelector('.play-btn');
		var vidoeWrap = document.querySelector('.video-wrap');
		var videoContainer = document.querySelector('.youtube-video');
		var videoPreviewContainer = document.querySelector('.video-preview-container');

		var vidoeIframe = '<iframe width="100%" height="100%" src="https://www.youtube.com/embed/X3djV7_7CG0?controls=0%&autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';

		burgerBtn.addEventListener('click', function (e) {
			toggleMobileNav();
		});

		if (playBtn) {
			playBtn.addEventListener('click', function (e) {
				videoPreviewContainer.style.display = 'none';
				vidoeWrap.classList.add('video-shown');
				videoContainer.classList.remove('hidden');
				videoContainer.innerHTML = vidoeIframe;
			})
		}

		function toggleMobileNav() {
			mobileMenuShown ? mobileMenu.classList.remove('show') : mobileMenu.classList.add('show');
			mobileMenuShown ? burgerBtn.classList.remove('active') : burgerBtn.classList.add('active');
			mobileMenuShown = !mobileMenuShown;
		}

		initDropDowns();
		webpCheck();
		storeSignUpSources();
		signUpClickHandler();
		attachCookiesPolicyBanner();
	};

	function storeSignUpSources() {
		const names = {};
		const queryParams = {};
		names[GCLID] = 'googleads';
		names[FBCLID] = 'facebookads';
		new URLSearchParams(window.location.search).forEach((value, key) => queryParams[key] = value);

		const key = Object.keys(queryParams).find(key => key === GCLID || key === FBCLID);
		if (key) {
			localStorage.clear();
			localStorage.setItem(key, JSON.stringify({
				key,
				name: names[key],
				value: queryParams[key],
				date: new Date().getTime()
			}));
		}
	}

	function signUpClickHandler() {
		const oneMonth = 2592000000;
		const savedCountryCode = sessionStorage.getItem('country');

		let countryCode = 'ES';
		let source;

		// try to fetch client country code
		if (!savedCountryCode)
			$.get({
				url: 'https://ipinfo.io/?token=92acfe38895872',
				success: res => {
					if (res && res.country && res.country.length)
						countryCode = res.country;
				}
			});
		else
			countryCode = savedCountryCode.toLocaleUpperCase();

		try {
			source = JSON.parse(localStorage.getItem(GCLID) || localStorage.getItem(FBCLID));
		} catch (e) {
		}

		const docfavUrl = document.getElementById('docfavUrl').dataset.docfavUrl;
		const links = document.querySelectorAll('[href="' + docfavUrl + '/sign-up' + '"]');

		links.forEach(link => {
			link.onclick = (e) => {
				let href = docfavUrl + '/sign-up';
				e.stopPropagation();
				e.preventDefault();
				if (source?.name && source.date + oneMonth > new Date().getTime()) {
					href += '?source=' + source.name;
				} else {
					href += '?source=pro'
				}
				window.location.href = replaceTLDDomain(href, countryCode);
			}
		})
	}

	function replaceTLDDomain(href, countryCode) {
		switch (countryCode) {
			case 'MX':
			case 'PE':
			case 'CO':
			case 'CL':
				return href.replace('.com', '.' + countryCode.toLowerCase());
			case 'AR':
				return href.replace('.com', '.com.ar');
			default:
				return href;
		}
	}

	// DROP DOWN //

	function initDropDowns() {
		const elems = document.querySelectorAll('[drop-down-container]');
		elems.forEach(elem => {
			const toggle = elem.parentNode.querySelector('[drop-down-toggle]');
			appendToDropDownContainer(elem, toggle, false);
		})
	}

	function appendToDropDownContainer(elem, toggle, closeByOutSideClick) {
		const container = createDropDownContainer(closeByOutSideClick);
		const parent = elem.parentNode;
		container.dropDownPanelRef.appendChild(elem);
		parent.appendChild(container.dropDownContainerRef);
		toggle.addEventListener('click', () => {
			container.toggle();
			container.isDropDownPanelShown() ? toggle.classList.add('open') : toggle.classList.remove('open');
		})
	}

	function createDropDownContainer(closeByOutSideClick) {
		let dropDownPanelShown;
		const dropDownContainer = document.createElement('div');
		dropDownContainer.classList.add('dropdown-container');
		dropDownContainer.setAttribute('tabindex', 0);

		const dropDownPanel = document.createElement('div');
		dropDownPanel.classList.add('dropdown-panel');
		dropDownPanel.classList.add('hidden');

		dropDownContainer.appendChild(dropDownPanel);
		dropDownContainer.addEventListener('focusout', focusOutListener);

		const closeDropDownPanel = () => {
			dropDownContainer.blur();
			dropDownPanelShown = false;
			dropDownPanel.classList.remove('shown');
			dropDownPanel.classList.add('hidden');
		};

		const openDropDownPanel = () => {
			dropDownContainer.focus();
			dropDownPanelShown = true;
			dropDownPanel.classList.remove('hidden');
			dropDownPanel.classList.add('shown');
		};

		const toggle = () => {
			dropDownPanelShown ? closeDropDownPanel() : openDropDownPanel();
		};

		const focusOutListener = (event) => {
			if (!event.currentTarget.contains(event.relatedTarget)) {
				setTimeout(() => {
					closeByOutSideClick && (dropDownPanelShown = false);
				}, 10);
			}
		};

		const isDropDownPanelShown = () => !!dropDownPanelShown;

		dropDownContainer.appendChild(dropDownPanel);

		return {
			dropDownContainerRef: dropDownContainer,
			dropDownPanelRef: dropDownPanel,
			toggle,
			openDropDownPanel,
			closeDropDownPanel,
			isDropDownPanelShown
		}
	}

	// WEBP //

	function webpCheck() {
		const _isWebpEnabled = isWebpEnabled();
		if (_isWebpEnabled && typeof _isWebpEnabled === 'object') {
			_isWebpEnabled.then(val => !val && replaceWebpImages())
		} else {
			replaceWebpImages();
		}
	}

	function replaceWebpImages() {
		document.querySelectorAll('[webp]').forEach(elem => {
			const originalFormat = elem.getAttribute('data-original-format');
			console.log(originalFormat);
			if (elem.tagName === 'IMG') {
				const val = elem.getAttribute('src');
				elem.setAttribute('src', val.replace('.webp', '.' + originalFormat))
			} else {
				const val = elem.style.backgroundImage;
				elem.style.backgroundImage = val.replace('.webp', '.' + originalFormat)
			}
		})
	}

	function isWebpEnabled() { // :boolean | Promise<boolean>
		if (!self.createImageBitmap || isIOS() || isSafari() || !promisesCheck()) {
			return false
		}

		const webpImage = new Image();
		webpImage.src = 'data:image/webp;base64,UklGRjIAAABXRUJQVlA4ICYAAACyAgCdASoCAAEALmk0mk0iIiIiIgBoSygABc6zbAAA/v56QAAAAA==';

		return new Promise(resolve => {
			webpImage.onload = () => {
				resolve(webpImage.width === 2 && webpImage.height === 1);
			}
		})
	}

	function isSafari() {
		return navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
			navigator.userAgent &&
			navigator.userAgent.indexOf('CriOS') === -1 &&
			navigator.userAgent.indexOf('FxiOS') === -1;
	}

	function promisesCheck() {
		return typeof Promise !== 'undefined' && Promise.toString().indexOf("[native code]") !== -1
	}

	function isIOS() {
		return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
	}

	function attachCookiesPolicyBanner() {
		attachBanner.default(
			document.body,
			{
				cookieName: 'docfav_gdpr',
				heading: 'Personalizamos tu experiencia',
				description: 'En docfav utilizamos cookies y otras tecnologías para mantener la fiabilidad y la seguridad de nuestra página web, para medir su rendimiento y para ofrecer una experiencia personalizada. Para ello, recopilamos información sobre los usuarios, su comportamiento y sus dispositivos',
				acceptLabel: 'Aceptar cookies',
				settingsLabel: 'Configurar',
				closeLabel: 'Close window',
				showEditIcon: false,
				choices: {
					necessary: {
						label: 'Cookies necesarias',
						description: 'Utilizado para control de cookies.',
						value: true
					},
					tracking: {
						label: 'Utilizado para control de cookies.',
						description: 'Para campañas publicitarias.',
						value: true
					},
					analytics: {
						label: 'Cookies para analítica',
						description: 'Utilizado para control de analítica web, Google analytics y hotjar.',
						value: true
					},
					marketing: {
						label: 'Cookies de marketing',
						description: 'Utilizadas con fines de marketing.',
						value: true
					}
				},
			}
		);
	}
})();


$(function () {
	$(".dropdown-toggle").on("click", function () {
		$(this).siblings(".dropdown-list-wrap").toggleClass('active');
	});

	// scrollTo anchor
	$('.sections-nav a[href^="#"], .scrollTo').on('click', function (e) {
		e.preventDefault();
		var anchor = $(this).attr('href');
		var topPos = $(anchor).offset().top;
		var offset = 120;
		$('html, body').animate({
			scrollTop: topPos - offset
		}, 500);
	});

	$(window).on('scroll load', function () {
		$('.landing-nav-main-wrap').toggleClass('fixed', $(this).scrollTop() > 0);
	});


	if ($('.cocoen').length) {
		new Cocoen(document.querySelector('.cocoen'));
	}

	$('.radio-toggle-wrap input').on('change', function () {
		var type = $(this).val();
		$('.dayweek-img').removeClass('active');
		$('.dayweek-img.' + type).addClass('active');
	});

	$('.collapse-headline').on('click', function () {
		$('.collapse-wrap, .collapse-img-wrap').removeClass('active');
		var $parent = $(this).closest('.collapse-wrap');
		var parentId = $parent.attr('id');
		$parent.addClass('active');
		$('.collapse-img-wrap#' + parentId + '-img').addClass('active');
	});

	// Phone Modal
	if ($('#phone').length) {
		var input = document.querySelector("#phone");
		var iti = intlTelInput(input, {
			preferredCountries: ["es"],
			onlyCountries: ["es", "mx", "co", "ar", "pe", "cl"]
		});

		$('#phone_form').on('submit', function (e) {
			e.preventDefault();
			var phone = $("#phone").val();
			var code = '+' + iti.getSelectedCountryData().dialCode;
			var docfavUrl = document.getElementById('docfavUrl').dataset.docfavUrl;
			var apiUrl = docfavUrl + '/api-backend';
			$.post(apiUrl + '/v1/es_ES/leads/call?telephone=' + code + phone, {}, function(res) {
				$('.phone-modal-body .headline').html('Gracias, le llamaremos en unos instantes.');
				$('#phone_form').html('');
			});
		});
	}

	$('.phone-modal-toggle').on('click', function () {
		$('.phone-modal').addClass('shown');
	});
	$('.modal-backdrop').on('click', function () {
		$('.phone-modal').removeClass('shown');
	});
});


$(window).on("load scroll resize", function () {
	animate();
});

function animate() {
	var $animatedBox = $(".animated-box");
	if (!!$animatedBox.length) {
		var wHeight = $(window).height();
		var wScroll = $(window).scrollTop();
		var offsetInit = window.innerWidth < 767 ? 70 : 55;
		var isPageBottom = $(document).height() - ($(window).scrollTop() + $(window).height()) < 95;
		$animatedBox.each(function () {
			var elPos = $(this).offset().top;
			var offset = $(this).attr("data-offset") || offsetInit;
			var animation = $(this).attr("data-enter") || '';
			var ratio = isPageBottom ? 0 : parseInt((wHeight / 100) * offset);
			if (elPos < wScroll + ratio || isPageBottom) {
				$(this).addClass('animated ' + animation);
			}
		});
	}
}


